<template>
	<div class="about-detail">
    <h2 id="intro01">{{detail.name}}</h2>
    <div v-html="detail.content"></div>
<!-- 		<h2 id="intro04">服务范围</h2>
       <div class="">
          <div class="item">
             <div class="ultitle">设备研发与制造</div>
             <ul>
                <li>增减材复合制造设备</li>
                <li>送粉式增材制造设备</li>
                <li>移动修复设备</li>
                <li>各类型激光熔覆头</li>
             </ul>
          </div>
          <div class="item">
             <div class="ultitle">激光增材制造</div>
             <ul>
                <li>零件增材制造</li>
                <li>增减材一体化制造</li>
                <li>修复再制造</li>
                <li>工艺研发及定制</li>
                <li>材料定制</li>
             </ul>
          </div>
          <div class="item">
             <div class="ultitle">光学分析</div>
             <ul>
                <li>光束焦点分析</li>
                <li>光束质量分析</li>
                <li>激光功率测量</li>
             </ul>
          </div>
          <div class="item">
             <div class="ultitle">软件开发</div>
             <ul>
                <li>增减材复合制造软件</li>
                <li>运动仿真软件</li>
             </ul>
          </div>
          <div class="item">
             <div class="ultitle">教育培训</div>
             <ul>
                <li>增减材制造技术基础理论</li>
                <li>增减材制造工艺流程</li>
                <li>增减材制造材料</li>
                <li>增减材制造实践应用</li>
             </ul>
           </div>
         </div> -->
	</div>
</template>

<script>
import { ref,onMounted,watch,toRefs } from 'vue'
import {useRoute} from 'vue-router'

export default {
	name: '',
	components:{},
	props:{
    list:{type:Array,default:[]}
  },
  setup(props){
    const route = useRoute();
    const detail = ref({content:'', name:''});
    const { list } = toRefs(props);
    onMounted(()=>{
      if(route.path==='/about/ab4'&&list.value.length>0){ 
        detail.value = list.value[3]
      }
    })
    watch(list,()=>{
      detail.value = list.value[3]
    })
    return {detail}
  }
}
</script>

<style scoped type="text/css">
  #intro01{font-size:22px; color:#111; font-weight: bold; padding:5px 0; text-align:center; margin:10px 0 20px 0; border-bottom:1px solid #eee;}
</style>